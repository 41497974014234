<template>
  <div class="flex flex-col mx-auto max-w-7xl lg:px-8">
    <VHeader class="pb-6" :title="$t('home.testimonials.header')"></VHeader>

    <div class="flex-row flex-1 hidden gap-4 lg:flex">
      <div class="flex flex-col w-1/3 gap-4">
        <div
          v-for="(testimonial, index) in testimonials.slice(0, 1)"
          :key="'first-' + index"
          class="flex-1 p-8 bg-gray-200 rounded-2xl"
        >
          <QuoteIcon class="mb-4" />
          <span class="text-gray-900">{{ testimonial.testimonial }}</span>
          <div class="flex mt-8 space-x-3">
            <nuxt-img
              class="w-12 h-12 rounded-full"
              :src="testimonial.photo"
              alt=""
              loading="lazy"
            />
            <div class="flex flex-col space-y-1">
              <span class="text-sm font-bold text-gray-900">{{
                testimonial.author
              }}</span>
              <span class="text-sm text-gray-700">{{
                testimonial.company
              }}</span>
            </div>
          </div>
        </div>
        <div class="flex-1 pb-10 rounded-2xl">
          <nuxt-img
            class="object-cover w-full h-full rounded-lg"
            src="/testimonials/testimonial_photo1.png"
            alt=""
            loading="lazy"
          />
        </div>
      </div>

      <div class="flex flex-col w-1/3 gap-4">
        <div
          v-for="(testimonial, index) in testimonials.slice(1, 4)"
          :key="'second-' + index"
          class="flex-1 p-8 bg-gray-200 rounded-2xl"
        >
          <QuoteIcon class="mb-4" />
          <span class="text-gray-900">{{ testimonial.testimonial }}</span>
          <div class="flex mt-8 space-x-3">
            <nuxt-img
              class="w-12 h-12 rounded-full"
              :src="testimonial.photo"
              alt=""
              loading="lazy"
            />
            <div class="flex flex-col space-y-1">
              <span class="text-sm font-bold text-gray-900">{{
                testimonial.author
              }}</span>
              <span class="text-sm text-gray-700">{{
                testimonial.company
              }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-col w-1/3 gap-4">
        <div class="relative flex-1 rounded-2xl">
          <nuxt-img
            class="object-cover w-full h-full rounded-lg"
            src="/testimonials/testimonial_photo2.png"
            alt=""
            loading="lazy"
          />
          <div class="absolute inset-0 bg-cblack opacity-30 rounded-2xl"></div>
        </div>

        <div
          v-for="(testimonial, index) in testimonials.slice(4, 5)"
          :key="'third-' + index"
          class="flex-1 p-8 mb-10 bg-gray-200 rounded-2xl"
        >
          <QuoteIcon class="mb-4" />
          <span class="text-gray-900">{{ testimonial.testimonial }}</span>
          <div class="flex mt-8 space-x-3">
            <nuxt-img
              class="w-12 h-12 rounded-full"
              :src="testimonial.photo"
              alt=""
              loading="lazy"
            />
            <div class="flex flex-col space-y-1">
              <span class="text-sm font-bold text-gray-900">{{
                testimonial.author
              }}</span>
              <span class="text-sm text-gray-700">{{
                testimonial.company
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex p-4 space-x-4 overflow-x-auto lg:hidden no-scrollbar">
      <div
        v-for="(testimonial, index) in testimonials"
        :key="index"
        class="flex-shrink-0 p-4 bg-gray-200 w-80 rounded-2xl"
      >
        <QuoteIcon class="mb-4" />
        <span class="text-gray-900">{{ testimonial.testimonial }}</span>
        <div class="flex mt-4 space-x-3">
          <nuxt-img
            class="w-12 h-12 rounded-full"
            :src="testimonial.photo"
            alt=""
            loading="lazy"
          />
          <div class="flex flex-col space-y-1">
            <span class="text-sm font-bold text-gray-900">{{
              testimonial.author
            }}</span>
            <span class="text-sm text-gray-700">{{ testimonial.company }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import QuoteIcon from '../components/icons/QuoteIcon.vue'

const { t } = useI18n()

const testimonials = [
  {
    testimonial: t('home.testimonials.klaus[0]'),
    author: t('home.testimonials.klaus[1]'),
    company: t('home.testimonials.klaus[2]'),
    photo: './testimonials/klaus_author.png',
  },
  {
    testimonial: t('home.testimonials.maya_power[0]'),
    author: t('home.testimonials.maya_power[1]'),
    company: t('home.testimonials.maya_power[2]'),
    photo: './testimonials/maya_author.png',
  },
  {
    testimonial: t('home.testimonials.spedition[0]'),
    author: t('home.testimonials.spedition[1]'),
    company: t('home.testimonials.spedition[2]'),
    photo: './testimonials/spedition_author.png',
  },
  {
    testimonial: t('home.testimonials.kluth[0]'),
    author: t('home.testimonials.kluth[1]'),
    company: t('home.testimonials.kluth[2]'),
    photo: './testimonials/kluth_author.png',
  },
  {
    testimonial: t('home.testimonials.yourtrucks[0]'),
    author: t('home.testimonials.yourtrucks[1]'),
    company: t('home.testimonials.yourtrucks[2]'),
    photo: './testimonials/yourtrucks_author.png',
  },
]
</script>
