<template>
  <div @vue:mounted="vMyDirective.scrollEvent">
    <NuxtLayout name="public">
      <Head>
        <Title>{{ $t('metaData.index.title') }}</Title>
        <Meta name="description" :content="$t('metaData.index.description')" />
      </Head>

      <HomeHero>
        <div class="flex flex-col">
          <HeroVehicleSearchBar />
          <Trustpilot
            is-hero-version
            :score="trustPilotScore"
            :show-evaluation="false"
            :is-logo-visible="false"
            class="mt-4"
          />
        </div>
      </HomeHero>

      <HomeReferencesSection class="mb-5 md:mt-10" />

      <HomeSectionContainer>
        <HomeNetworkSection />
      </HomeSectionContainer>

      <HomeVehiclesSection :assets="vehicleAssets" class="py-8" />

      <HomeLocationsSection class="py-8" />

      <HomeSectionContainer>
        <HomeBenefitsSection />
      </HomeSectionContainer>

      <HomeBecomeRentalCompanySection class="my-8" />

      <Trustpilot :score="trustPilotScore" :show-evaluation="false" />

      <HomeTestimonialsSection class="mt-6 mb-8" />

      <HomeSectionContainer>
        <HomePressPublicationsSection :articles="articles" />
        <HomeFaqSection :faq="faq" />
        <HeroVehicleSearchBar class="flex justify-center" />
        <Trustpilot :score="trustPilotScore" is-extended class="mt-12" />
      </HomeSectionContainer>

      <HomeStickyArrow v-if="scrollY" />
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
import { TrailerSubTypes, TruckSubTypes, VehicleCategory } from '@/types/enums'
import type {
  ArticleContentItem,
  AssetReturnType,
  FaqContent,
} from '@/types/interfaces'

const trustPilotScore = useRuntimeConfig().public.TRUSTPILOT_SCORE

// MARK: Data fetching

const { data: articles } = await useLazyAsyncData(
  'articles',
  () =>
    queryContent('articles').limit(2).sort({ date: -1 }).find() as Promise<
      ArticleContentItem[]
    >
)

const { data: faq } = await useLazyAsyncData(
  'faq',
  () => queryContent('faq/customer').findOne() as Promise<FaqContent>
)

// MARK: Scroll to top button

const scrollY = ref(false)

const vMyDirective = {
  scrollEvent() {
    window.addEventListener('scroll', this.showWhenScroll)
  },
  showWhenScroll() {
    window.scrollY > 500 ? (scrollY.value = true) : (scrollY.value = false)
  },
}

// MARK: Data fetching

const { data: vehicleAssets } = useLazyFetch<AssetReturnType[]>(
  '/api/asset-types',
  {
    query: {
      subtypes: [
        TruckSubTypes.standard,
        TrailerSubTypes.curtainsider,
        TrailerSubTypes.box_trailer,
        TrailerSubTypes.reefer,
      ],
      categories: [VehicleCategory.truck, VehicleCategory.trailer],
    },
  }
)
</script>
